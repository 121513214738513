/*
Видимость виджетов. https://youtrack.lifehacker.ru/issue/LH-928

Логика работы

Одной фразой логику можно описать так: если срабатывает хотя бы одно из условий "Показывать, если" и
при этом не срабатывает ни одного из условий "Скрывать, если", то показываем виджет. В противном случае скрываем.

Пошагово оно работает так:

    Проверяем show
    Если в show ничего нет, то идём в пункт 2.
    Если в show что-то есть, то при срабатывании ХОТЯ БЫ ОДНОГО из условий идём в п.2.
    Если ни один не сработал - всё, НЕ ПОКАЗЫВАЕМ.

    Проверяем hide
    При срабатывании ХОТЯ БЫ ОДНОГО из условий в hide всё, НЕ ПОКАЗЫВАЕМ.

Всё. Если не наступило "НЕ ПОКАЗЫВАЕМ", то показываем.
 */

// на фронте spec_projects находится в categories. Поэтому заменяем тип на category
const normalizeVisibilityData = (currentPageVisibilityData: VisibilityCondition[], widgetVisibilityData: TWidget['visibility']) => {
  const replaceTypeSpecProjectsToCategory = (item: VisibilityCondition) => {
    if (item.type === 'spec_projects') {
      return {
        ...item,
        type: 'category',
      };
    }
    return item;
  };
  const normalizedCurrentPageVisibilityData = currentPageVisibilityData.map(replaceTypeSpecProjectsToCategory);
  const normalizedWidgetVisibilityData = {
    show: widgetVisibilityData?.show?.map(replaceTypeSpecProjectsToCategory),
    hide: widgetVisibilityData?.hide?.map(replaceTypeSpecProjectsToCategory),
  };
  return {
    normalizedCurrentPageVisibilityData,
    normalizedWidgetVisibilityData,
  };
};
export const isVisible = (currentPageVisibilityData: VisibilityCondition[], widgetVisibilityData: TWidget['visibility']): boolean => {
  const { normalizedCurrentPageVisibilityData, normalizedWidgetVisibilityData } = normalizeVisibilityData(currentPageVisibilityData, widgetVisibilityData);

  if (normalizedWidgetVisibilityData?.show?.length) {
    let isConditionFulfilled: boolean = false;
    normalizedWidgetVisibilityData.show.forEach((item) => {
      normalizedCurrentPageVisibilityData.forEach((pageItem) => {
        if (item.type === pageItem.type && ((item.id && item?.id === pageItem?.id) || (item?.value && item?.value === pageItem?.value))) {
          isConditionFulfilled = true;
        }
      });
    });

    if (!isConditionFulfilled) {
      return false;
    }
  }

  if (normalizedWidgetVisibilityData?.hide?.length) {
    let isConditionFulfilled: boolean = false;
    normalizedWidgetVisibilityData.hide.forEach((item) => {
      normalizedCurrentPageVisibilityData.forEach((pageItem) => {
        if (item.type === pageItem.type && ((item.id && item?.id === pageItem?.id) || (item.value && item?.value === pageItem?.value))) {
          isConditionFulfilled = true;
        }
      });
    });

    if (isConditionFulfilled) {
      return false;
    }
  }

  return true;
};
