/**
 Алгоритм работы в файле ~/libs/widgetsVisibility

 Про ингредиенты:

 - фильтры, исключающие ингредиенты, никак не влияют на видимость виджетов.
 Все условия видимости “позитивные“, то есть реагируют на наличие.
 На отсутствие они реагировать не должны. “показывать если красное мясо” не работает
 на фильтре без говядины, красного мяса или мяса вообще. То же и с сокрытием: “скрывать
 если красное мясо” не срабатывает на фильтрах “без красного мяса“, “без мяса” и “без говядины“:
 да, там может появиться свинина, а может и не появиться, значит условие не должно сработать.

 - условие считается выполненным, если страница соответствует таксономии или любым её дочерним
 таксономиям. Значит в информации о странице нужны как сама таксономия, так и все её родители.
 Если у нас рецепт яичницы с беконом, то оно как бы состоит из яиц куриных, бекона, а также
 неявно из свинины, красного мяса и мяса вообще, и показ-сокрытие должно триггериться на
 любой из них, как на сами конкретные ингредиенты, так и родительские. Добавлю набор
 родителей ко всем иерархическим таксономиям.
 */
type WidgetData = {
  show?: RecipeVisibilityCondition[];
  hide?: RecipeVisibilityCondition[];
}

const RECIPE_WIDGET_CONDITION_TYPES = ['dish-type', 'cooking-method', 'ingredient', 'cuisine', 'recipe-keyword'] as const;

const TYPES: Record<typeof RECIPE_WIDGET_CONDITION_TYPES[number], keyof RecipesVisibilityData> = {
  ingredient: 'ingredients',
  'dish-type': 'dishTypes',
  cuisine: 'cuisines',
  'recipe-keyword': 'keywords',
  'cooking-method': 'cookingMethods',
};

export const isRecipesWidgetVisible = (pageData: RecipesVisibilityData, widgetData: WidgetData | null): boolean => {
  let isVisible = true;

  if (!widgetData || !widgetData.show || !widgetData.hide) {
    return isVisible;
  }

  const typeKeys = Object.keys(TYPES);
  const { show, hide } = widgetData;
  if (show.length) {
    show.every((item) => {
      if (typeKeys.includes(item.type)) {
        isVisible = pageData[TYPES[item.type]].includes(item.id);
      }
      return isVisible;
    });
  }
  if (hide.length && isVisible) {
    hide.every((item) => {
      if (typeKeys.includes(item.type)) {
        isVisible = !pageData[TYPES[item.type]].includes(item.id);
      }
      return isVisible;
    });
  }

  return isVisible;
};
