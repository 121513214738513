import { getUniqueListBy } from '~/utils';

export class Scripts implements IScripts {
  constructor (
    scripts: Array<TScript>,
    inlineScripts: Array<TScript>,
    loadedCallback: () => void = () => {},
  ) {
    const uniqueScriptAttributes = getUniqueListBy(scripts.map(item => item.attributes), 'src');
    this.scripts = uniqueScriptAttributes.map(this.createScript);
    this.inlineScripts = inlineScripts.map(this.getInlineScriptText);
    this.loadedCallback = loadedCallback;
  }

  private readonly scripts: Array<HTMLScriptElement> = [];

  private readonly inlineScripts: Array<string> = [];

  private readonly loadedCallback: () => void;

  private count: number = 0;

  private get loadedScriptsCount () {
    return this.count;
  }

  private set loadedScriptsCount (value) {
    this.count = value;

    // если загрузка всех скриптов, добавленных на страницу, закончилась (независимо от того успешно или нет)
    if (this.count === this.scripts.length) {
      this.runInlineScripts();
      this.loadedCallback();
    }
  }

  private createScript = (item: TScript['attributes']): HTMLScriptElement => {
    const script = document.createElement('script');

    script.src = item?.src ?? '';
    // для какой-то цели был добавлен тут:
    // https://gitlab.lifehacker.ru/devhacker/lh-website-client/-/blob/c69b05924348738be0608c3b04ef7bca87442382/src/libs/Scripts.ts
    script.defer = true;
    // Динамически загружаемые скрипты по умолчанию ведут себя как «async».
    // поэтому если нужен defer, явно указываем еще и async = false
    script.async = false;

    const incrementLoadedScriptsCount = () => {
      this.loadedScriptsCount += 1;
    };
    script.onload = incrementLoadedScriptsCount;
    script.onerror = incrementLoadedScriptsCount;

    return script;
  }

  private getInlineScriptText = ({ children }: TScript): string => {
    let result = '';

    if (children) {
      children.forEach(({ text }: { text: string }) => {
        result += text;
      });
    }

    return result;
  }

  private runInlineScripts = (): void => {
    this.inlineScripts.forEach((script: string) => {
      if (script.length) {
        // такое выполнение кода из строки можно отловить только через try/catch внутри строки
        const codeString = `try { ${script} } catch (e) {console.error("libs/Scripts error: ", e)}`;
        try {
          setTimeout(codeString, 0);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('libs/Scripts error: ', e);
        }
      }
    });
  }

  addScripts = (): void => {
    if (!this.scripts.length) {
      this.runInlineScripts();
      this.loadedCallback();
      return undefined;
    }

    document.body.append(...this.scripts);
  }

  removeScripts = (): void => {
    this.scripts.forEach(script => script.remove());
  }
}
