import type { Context } from '@nuxt/types';
import { API_GATEWAY_ENABLED } from '~/constants/config';
import fetchApiGateway from '~/utils/api/fetch-apigateway';
import fetchSinglepost from '~/utils/api/fetch-singlepost';
import { isPreviewPage, isArticlePage, isAmpPage } from '~/router';

// Middleware должна быть глобальной, чтобы вызываться перед layout
// это нужно для определения типа статьи (здоровье/обычная)
// этот миддлвар игнорирует роуты здоровья, потому что без этого он вызывается, а внутри HealthNavigator нет
// поэтому внутри HealthNavigator мы вызываем этот с force = true
export default async (ctx: Context, force: boolean = false): Promise<any> => {
  // если один и тот-же путь, но меняются только параметры/хэш
  if (ctx.from?.path === ctx.route.path) { return; }

  if (isAmpPage(ctx.route.name)) { return await fetchSinglepost(ctx); }

  if (
    isArticlePage(ctx.route.name) ||
    isPreviewPage(ctx.route.name) ||
    force
  ) {
    return API_GATEWAY_ENABLED
      ? await fetchApiGateway(ctx)
      : await fetchSinglepost(ctx);
  }
};
