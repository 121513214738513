import Vue from 'vue';

export default Vue.extend({
  methods: {
    onClickFavoriteButton () {
      this.$emit('click-on-favorite-button');
    },

    onClickRestoreButton () {
      this.$emit('click-on-restore-button');
    },

    onClickLink () {
      this.$emit('click-on-article-card');
    },

    onContextMenu () {
      this.$emit('show-context-menu');
    },

    getArticleCardControlsProps (props: ILhArticleCardProps): ILhArticleCardControlsProps {
      const {
        url,
        commentsEnabled,
        commentsCount,
        user,
        favorite,
        folders,
        isShowFavoriteFolderName,
        favoriteMenuOffsets,
        internalUrl,
      } = props;

      return {
        url,
        commentsEnabled,
        commentsCount,
        user,
        favorite,
        folders,
        isShowFavoriteFolderName,
        favoriteMenuOffsets,
        internalUrl,
      };
    },

    async $_mixin_onFavoriteAction () {
      // setTimeout чтобы safari успел отрисовать
      // hover у элемента под курсором
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, 300);
      });
      const hoveredArticleCardsWithoutLast = Array.from(document.querySelectorAll(
        '.lh-small-article-card:hover, .lh-middle-article-card:hover, .lh-large-article-card:hover, .lh-large-article-card__wrapper:hover',
      )).slice(0, -1);
      if (hoveredArticleCardsWithoutLast.length) {
        hoveredArticleCardsWithoutLast.forEach((articleCard) => {
          articleCard.classList.add('lh-article-card--no-hover');
        });
      }
    },
    $_mixin_onFavoriteDelete (favorite: IFavoriteItem): void {
      this.$emit('favorite-delete', favorite);
    },

    $_mixin_onFavoriteCreateFolderAndAdd (nameFolder: string): void {
      this.$emit('favorite-create-folder-and-add', nameFolder);
    },

    $_mixin_onFavoriteCreateFolderAndTransfer (nameFolder: string, favorite: IFavoriteItem): void {
      this.$emit('favorite-create-folder-and-transfer', nameFolder, favorite);
    },

    $_mixin_onFavoriteTransferToFolder (favorite: IFavoriteItem, folder: IFolderItem) {
      this.$emit('favorite-transfer-to-folder', favorite, folder);
    },

    $_mixin_onFavoriteAddToFolder (folder: IFolderItem) {
      this.$emit('favorite-add-to-folder', folder);
    },

    $_mixin_onFavoriteDeferred (deferredAction: Function, ctx: any) {
      this.$emit('favorite-deferred', deferredAction, ctx);
    },

    $_mixin_onClickedOnAuthor (link: string): void {
      this.$emit('clicked-on-author', link);
    },

    $_mixin_onClickedOnCategory (link: string, categoryName: string): void {
      // @ts-ignore
      this.$emit('clicked-on-category', link, categoryName, this.title || '');
    },

    $_mixin_onClickedComments (link: string): void {
      this.$emit('clicked-on-comments', link);
    },
  },
});
