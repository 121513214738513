// #1rvz0xc
// Такой же миксин в LH-UI
import Vue from 'vue';

import { isMobileOnly, isIOS } from 'mobile-device-detect';

export default Vue.extend({
  data: () => ({
    mixin__smartBanner__windowInnerHeight: 0 as number,
  }),

  computed: {
    mixin__smartBanner__isIphone (): boolean {
      return isMobileOnly && isIOS;
    },
    mixin__smartBanner__isSmartBanner (): boolean {
      if (typeof document === 'undefined' || !this.mixin__smartBanner__isIphone) {
        return false;
      }

      // smart banner на ios занимает область viewport
      // при этом можно отследить есть он на страницу или нет:
      // сравнить window.innerHeight и document.documentElement.clientHeight
      // если window.innerHeight меньше, то разница между значениями
      // как раз равна 84 - высоте смарт-баннера
      return this.mixin__smartBanner__windowInnerHeight < document.documentElement.clientHeight;
    },
  },

  mounted () {
    if (this.mixin__smartBanner__isIphone) {
      this.mixin__smartBanner__windowInnerHeight = window.innerHeight;
      window.addEventListener('resize', this.$__mixin__smartBanner__onResizeWindowInnerHeight);
    }
  },

  beforeDestroy () {
    this.$__mixin__smartBanner__removeListener();
  },

  methods: {
    $__mixin__smartBanner__onResizeWindowInnerHeight (): void {
      this.mixin__smartBanner__windowInnerHeight = window.innerHeight;
    },
    $__mixin__smartBanner__removeListener (): void {
      window.removeEventListener('resize', this.$__mixin__smartBanner__onResizeWindowInnerHeight);
    },
  },
});
