// Модуль для кэширования ответов от запросов к API
import { ICacheClient } from '~/services/cache-client.interface';

// Ходит в апи, и если на сервере то сохраняет ответ в redis
function fetchWpData (
  wpApiFunction: () => Promise<any>,
  cachedKey: string,
  redisClient?: ICacheClient,
) {
  return wpApiFunction()
    .then(({ status, data }) => {
      if (status === 200) {
        const serialized = JSON.stringify(data);
        if (redisClient) {
          redisClient.set(cachedKey, serialized);
        }
        return data;
      }
    });
}

/**
 * На клиенте заправишает и отдает данные через fetchWpData
 * На севрере сначала ищет в кеше, если находит то отдает то что в нем
 * иначе просто запрашивает и отдает
 *
 * @param {actionName} string - имя экшена состояния
 * @param {wpApiFunction} Promise<WordpressApiFunction> - Функция  интерфейс АПИ ВП, которая получает информацию с бэкенда
 * @param {cacheClient} [cacheClient] - Интерфейс клиента кэширования
 */
export function defaultDataCaching (
  actionName: string,
  wpApiFunction: (...args: any[]) => Promise<any>,
  cacheClient?: ICacheClient,
): Promise<any> {
  const cachedKey = 'store:' + actionName;
  if (process.client) {
    return fetchWpData(wpApiFunction, cachedKey);
  } else if (process.server && cacheClient) {
    const cached = cacheClient.get(cachedKey);
    return cached.then((rawData: any) => {
      if (rawData) {
        const data = JSON.parse(rawData);
        return data;
      } else {
        return fetchWpData(wpApiFunction, cachedKey, cacheClient);
      }
    });
  } else {
    return fetchWpData(wpApiFunction, cachedKey);
  }
}
