// @ts-ignore
import cloneDeep from 'lodash.clonedeep';

import { isObject, isArray, isString, has } from '~/utils';

export type AttrRegulations = {
  mandatory?: boolean;
  pattern?: RegExp;
  default?: any;
  allowedValues?: Array<any>;
};

export type TagRegulations = {
  global: boolean,
  attributes?: {
    [name: string]: boolean | AttrRegulations,
  },
};

export interface Settings {
  replaceableTags: Record<string, string>;
  replaceableIframes: Record<string, string>;
  patternsForIframes: Record<string, RegExp>;
  patternsForSrcId: Record<string, RegExp>;
  allowedHTMLTags: string[];
  globalAttributes: (string | RegExp)[];
  globalAttributesValues: {
    [attrName: string]: {
      pattern: RegExp;
    };
  };
  tagAttributesSpecification: {
    [tagName: string]: TagRegulations;
  }
}

type StructuredContentItem = {
  type: string,
  id: number,
  text: string,
  attributes: {
    [propName: string]: any
  },
  children: (StructuredContentItem | null)[]
};

export function sanitizeStructuredContent (
  dirtyContent: Array<StructuredContentItem | TScript>,
  settings: Settings,
): Array<TContent> {
  const {
    allowedHTMLTags,
    replaceableTags,
    globalAttributes,
    tagAttributesSpecification,
    globalAttributesValues,
    replaceableIframes,
    patternsForIframes,
    patternsForSrcId,
  } = settings;

  return (cloneDeep(dirtyContent) as StructuredContentItem[])
    .map(item => replaceTagToComponentIfNeeded(item, replaceableTags, patternsForIframes, replaceableIframes, patternsForSrcId))
    .map(item => removeNotAllowedTags(item, allowedHTMLTags))
    .map(item => sanitizeAttributes(item, tagAttributesSpecification, globalAttributes, globalAttributesValues))
    .filter(removeNullElements) as TContent[];
}

/**
 * Функция для замены тегов на компоненты по правилам replaceableTags
 *
 * @param item Данные тега
 * @param replaceableTags Объект в котором в key название тега, КОТОРЫЙ нужно заменить
 * и в value название тега НА КОТОРЫЙ нужно заменить
 * @param patternsForIframes Объект, в котором key - название сервиса который нужно найти у айфрейма в src
 * и value - паттерн, по которому мы его ищем в src
 * @param replaceableIframes Объект в котором key - iframe сервиса, value - тег ампа на который нужно заменить iframe
 * @param patternsForSrcId Объект в котором key - название сервиса, value - регулярное выражение которое ищет id конкретного ресурсы в ссылке на этот сервис
 * @return Новые данные тега с замененным типом когда нужно
 */
const replaceTagToComponentIfNeeded = (item: StructuredContentItem, replaceableTags: Settings['replaceableTags'], patternsForIframes: Settings['patternsForIframes'], replaceableIframes: Settings['replaceableIframes'], patternsForSrcId: Settings['patternsForSrcId']): StructuredContentItem => {
  const newItem = cloneDeep(item) as StructuredContentItem;
  if (newItem.attributes?.class?.match(/viqeo-embed/gm)) {
    newItem.type = 'amp-viqeo-player';
    newItem.children = [];
    return setAttributesForViqeo(newItem);
  }
  newItem.children = newItem.children.map(item => replaceTagToComponentIfNeeded(item as StructuredContentItem, replaceableTags, patternsForIframes, replaceableIframes, patternsForSrcId));

  if (has(replaceableTags, newItem.type)) {
    newItem.type = replaceableTags[newItem.type];
  }
  if (newItem.type === 'amp-iframe-wrapper') {
    return replaceIframeIfNeeded(newItem, replaceableIframes, patternsForIframes, patternsForSrcId);
  }
  return newItem;
};

const replaceIframeIfNeeded = (item: StructuredContentItem, replaceableTags: Settings['replaceableIframes'], patterns: Settings['patternsForIframes'], patternsForSrcId: Settings['patternsForSrcId']): StructuredContentItem => {
  for (const pattern in patterns) {
    if (item.attributes.src?.match(patterns[pattern])) {
      item.type = replaceableTags[pattern];
      return setAttributes(item, pattern as String, patternsForSrcId);
    }
  }
  return item;
};

const setAttributes = (item: StructuredContentItem, pattern: any, patternsForSrcId: Settings['patternsForSrcId']): StructuredContentItem => {
  switch (pattern) {
    case 'youtube':
      setAttributesForYouTube(item, patternsForSrcId[pattern]);
      break;
    default:
      break;
  }
  return item;
};

const setAttributesForViqeo = (item: StructuredContentItem): StructuredContentItem => {
  item.attributes['data-videoid'] = item.attributes['data-vnd'];
  item.attributes['data-profileid'] = item.attributes['data-profile'];
  item.attributes.layout = 'responsive';
  item.attributes.width = 560;
  item.attributes.height = 315;
  return item;
};

const setAttributesForYouTube = (item: StructuredContentItem, pattern: RegExp): StructuredContentItem => {
  // https://ask-dev.ru/info/2246/javascript-regex-how-do-i-get-the-youtube-video-id-from-a-url
  // если падает амп ютуба, скорее всего регулярка (pattern не обрабатывает какой-то пограничный случай)
  // регулярки находится в src > libs > sanitize-amp > settings, также присутсвует в тестах
  item.attributes['data-videoid'] = item.attributes.src.match(pattern)[2] || '';
  item.attributes.layout = 'responsive';
  return item;
};

/**
 * Удаление не разрешенных тегов (которые не входят в allowedHTMLTags)
 *
 * @param item Элемент из структурированного контента
 * @param allowedHTMLTags Разрешенные HTML теги
 * @return Если тег есть среди разрешенных возвращает данные тега c проверкой дочерних элементов, иначе null
 */
const removeNotAllowedTags = (
  item: StructuredContentItem,
  allowedHTMLTags: Settings['allowedHTMLTags'],
): StructuredContentItem | null => {
  const newItem = cloneDeep(item) as StructuredContentItem;
  const isAllowed = allowedHTMLTags.includes(newItem.type);
  if (!isAllowed) {
    return null;
  }
  newItem.children = newItem.children.map(item => removeNotAllowedTags(item as StructuredContentItem, allowedHTMLTags));
  return newItem;
};

/**
 * Функция для проверки того что у данного тега есть обязательные атрибуты в требованиях.
 *
 * @param regulations Правила для тега
 * @return У данного тега есть обязательные атрибуты в требованиях?
 */
const calcElemHasMandatoryAttributesInRegulations = (regulations: TagRegulations): boolean => {
  let hasMandatoryAttributes: boolean = false;
  if (has(regulations, 'attributes')) {
    for (const name in regulations.attributes) {
      if (isObject(regulations.attributes[name]) && (regulations.attributes[name] as AttrRegulations).mandatory) {
        hasMandatoryAttributes = true;
        break;
      }
    }
  }
  return hasMandatoryAttributes;
};

/**
 * Функция для проверки атрибута требованиям к глобальным атрибутам.
 *
 * @param name Имя атрибута
 * @param value Значение атрибута
 * @param globalAttributes Глобальные атрибуты
 * @param globalAttributesValues Значения глобальных атрибутов
 * @return Переданный атрибут есть среди глобальных и его значение соответствует требованиям
 */
const globalAttributesHasCurrentAttribute = (
  name: string,
  value: unknown,
  globalAttributes: Settings['globalAttributes'],
  globalAttributesValues: Settings['globalAttributesValues'],
): boolean => {
  const nameCorrect: boolean = globalAttributes.some((item): boolean => {
    if (isString(item)) {
      return (item === name);
    }
    return (item as RegExp).test(name);
  });
  const valueRules = globalAttributesValues[name];
  let valueCorrect: boolean = true;
  if (nameCorrect && valueRules) {
    valueCorrect = isString(value) && valueRules.pattern.test(value as string);
  }
  return nameCorrect && valueCorrect;
};

/**
 * Функция для нормализации атрибутов.
 *
 * @param item Данные элемента
 * @param tagAttributesSpecification Настройки для атрибутов
 * @param globalAttributes Глобальные атрибуты
 * @param globalAttributesValues Значения глобальных атрибутов
 * @return Элемент с корректными атрибутами или null когда обязательные атрибуты элемента не соответствуют требованиям
 * и не могут быть исправлены
 */
const sanitizeAttributes = (
  item: StructuredContentItem | null,
  tagAttributesSpecification: Settings['tagAttributesSpecification'],
  globalAttributes: Settings['globalAttributes'],
  globalAttributesValues: Settings['globalAttributesValues'],
): StructuredContentItem | null => {
  if (!item) {
    return item;
  }

  const newValue = cloneDeep(item) as StructuredContentItem;
  const { type, attributes } = newValue;

  const newAttributes: Record<string, any> = {};

  const currentAttributesSpecification: TagRegulations | null = has(tagAttributesSpecification, type) ? tagAttributesSpecification[type] : null;
  const elemHasMandatoryAttributes: boolean = currentAttributesSpecification ? calcElemHasMandatoryAttributesInRegulations(currentAttributesSpecification) : false;
  // Если есть обязательные атрибуты, перебираем правила и проверяем соответствие и правим то, что нужно поправить.
  // Если нельзя поправить обязательный атрибут, удаляем элемент.
  if (elemHasMandatoryAttributes) {
    const attributesRegulations = currentAttributesSpecification?.attributes as TagRegulations['attributes'];
    if (attributesRegulations) {
      for (const attrName in attributesRegulations) {
        const currentRules = attributesRegulations[attrName];
        // правила для данного атрибута имеют тип "объект" и атрибут обязательный
        if (isObject(currentRules) && currentRules?.mandatory) {
          // У тега нет такого атрибута
          if (!has(attributes, attrName)) {
            // в правилах для данного атрибута есть обязательное значение?
            // устанавливаем его и переходим к следующему атрибуту
            if (currentRules.default) {
              newAttributes[attrName] = currentRules.default;
              continue;
            // иначе удаляем элемент
            } else {
              return null;
            }
          }
          // В правилах атрибута есть список возможных значений
          if (currentRules.allowedValues) {
            // значение атрибута есть в списке возможных значений?
            // оставляем это значение и переходим к следующему атрибуту
            if (currentRules.allowedValues.includes(attributes[attrName])) {
              newAttributes[attrName] = attributes[attrName];
              continue;
            // иначе если в правилах есть дефолтное значение, устанавливаем его и переходим к следующему атрибуту
            } else if (currentRules.default) {
              newAttributes[attrName] = currentRules.default;
              continue;
            // иначе удаляем тег
            } else {
              return null;
            }
          }
          // в правилах атрибута есть паттерн, которому должно соответствовать значение
          if (currentRules.pattern) {
            // значение атрибута соответствует паттерну?
            // оставляем текущее значение и переходим к следующему атрибуту
            if (currentRules.pattern.test(attributes[attrName])) {
              newAttributes[attrName] = attributes[attrName];
            // иначе если есть в правилах дефолтное значение, устанавливаем его
            } else if (currentRules.default) {
              newAttributes[attrName] = currentRules.default;
            // иначе удаляем тег
            } else {
              return null;
            }
          }
        }
      }
    }
  }

  // Перебираем все остальные атрибуты элемента и удаляем те, которые не разрешены
  const elemHasAttributes: boolean = isObject(attributes) && !isArray(attributes);
  if (elemHasAttributes) {
    for (const attrName in attributes) {
      // если атрибут проверили в предыдущем цикле, ничего не делаем и переходим к следующему атрибуту
      if (has(newAttributes, attrName)) {
        continue;
      }

      const currentAttributeValue = attributes[attrName];
      const currentAttributeRegulations = (currentAttributesSpecification?.attributes && currentAttributesSpecification.attributes[attrName]) || null;
      const isAllowedGlobalAttributes: boolean = currentAttributesSpecification?.global !== false;
      // если в правилах для текущего атрибута значение true
      // оставляем как есть
      if (currentAttributeRegulations === true) {
        newAttributes[attrName] = currentAttributeValue;
      // если в правилах для данного атрибута объект
      } else if (isObject(currentAttributeRegulations)) {
        // если в правилах есть список возможных значений
        if (currentAttributeRegulations?.allowedValues) {
          // если значение есть в списке, оставляем как есть
          if (currentAttributeRegulations.allowedValues.includes(currentAttributeValue)) {
            newAttributes[attrName] = currentAttributeValue;
          }
        // иначе если в правилах есть паттерн
        } else if (currentAttributeRegulations?.pattern) {
          // если значение соответствует паттерну, оставляем значение
          if (currentAttributeRegulations.pattern.test(currentAttributeValue)) {
            newAttributes[attrName] = currentAttributeValue;
          }
        // если никаких правил нет оставляем значение
        } else {
          newAttributes[attrName] = currentAttributeValue;
        }
      // иначе если у данного элемента разрешены глобальные атрибуты
      } else if (isAllowedGlobalAttributes) {
        // проверяем есть ли данный атрибут среди глобальных и соответствует ли требованиям
        // если да то оставляем значение
        if (globalAttributesHasCurrentAttribute(attrName, currentAttributeValue, globalAttributes, globalAttributesValues)) {
          newAttributes[attrName] = currentAttributeValue;
        }
      }
      // иначе атрибут удаляется (не добавляется в newAttributes)
    }
  }

  const attributesToRemove = currentAttributesSpecification?.attributes
    ? Object.entries(currentAttributesSpecification.attributes)
      .filter(([_, value]) => value === false)
      .map(([name, _]) => name)
    : [];

  attributesToRemove.forEach((attributeName) => {
    delete newAttributes[attributeName];
  });

  newValue.attributes = newAttributes;
  newValue.children = newValue.children.map(item => sanitizeAttributes(item, tagAttributesSpecification, globalAttributes, globalAttributesValues));
  return newValue;
};

/**
 * Функция для удаления null среди дочерних элементов и удаления самого элемента если это null.
 * Мутирует переданные данные!
 *
 * @param item Данные тега или null
 * @return Элемент не null
 */
const removeNullElements = (item: StructuredContentItem | null): boolean => {
  if (!item) {
    return false;
  }
  item.children = item.children.filter(removeNullElements);
  return true;
};
