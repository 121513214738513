import Vue from 'vue';

import { getDateText } from '@devhacker/shared/utils/date';
import { getEditLinkHref, getRelativeLink, isHomeLink } from '~/utils';
import { FoldersState } from '~/store/folders';
import { ADS_LABELS, ADS_EXCEPTIONS } from '~/constants/ads-labels';

export default Vue.extend({
  computed: {
    articles (): Array<IArticle> {
      return [];
    },
  },

  methods: {
    // TO DO: похожий метод в
    // src/containers/TaxonomyPostsContainer/TaxonomyPostsContainer.vue,
    // src/containers/ZenRotationContainer.vue
    // можно объединить, при этом ВАЖНО сохранить значения и набор свойств, тк они отличаются
    // в миксинах и в виджете, например title
    // В миксине выбирается тестовый заголовок и сохраняется в сторе если он есть.
    // А в виджете только выбирается сохраненный тестовый заголовок из стора если он
    // есть или основной заголовок. Плюс в миксине кроме этого метода есть еще другие,
    // которые не нужны в виджете
    $_mixin_getArticleProps (article: IWidgetArticle): {[propName: string]: any} {
      const {
        id,
        categories,
        author,
        url: articleUrl,
        img_alt,
        isAdvertising,
        isPromo,
        commentsEnabled,
        img,
        defaultImg,
        teaserUrl,
        type,
        advertLabel,
        adsToken,
        tags,
      } = article;
      const internalUrl = teaserUrl ? false : isHomeLink(articleUrl);
      const url = teaserUrl || (internalUrl ? getRelativeLink(articleUrl) : articleUrl);
      const title = this.getTitle(article);

      return {
        id,
        categories,
        isPromo,
        isAdvertising: isAdvertising && !ADS_EXCEPTIONS.includes(advertLabel),
        isTeaser: !!teaserUrl,
        commentsEnabled,
        internalUrl,
        url,
        title,
        images: article.image?.sizes || img,
        imgAlt: img_alt || article.image?.alt || article.title,
        tags,
        defaultImages: defaultImg,
        authorText: author?.name || '',
        authorUrl: author?.profile || '',
        inFavorites: (this.$store.getters.getArticleFavoriteId(id) > 0),
        dateText: getDateText(article.date),
        commentsCount: this.$store.getters.getCommentsCount(id),
        isAuthenticatedInWordpress: (this.$store.state as IRootState).isAuthenticatedInWordpress,
        editLinkHref: getEditLinkHref(id),
        viewsCount: this.$store.getters.getViewsCount(id),
        user: this.$store.state.user as IRootState['user'],
        folders: this.$store.state.folders as FoldersState,
        favorite: this.$store.getters.getArticleFavorite(id) as IFavoriteItem,
        favoriteMenuOffsets: {
          top: 48, // учитывать fixed шапку
        } as TOffsets,
        type,
        advertLabel,
        partnerText: ADS_LABELS[advertLabel],
        adsToken,
      };
    },
    getTitle (article: IWidgetArticle): string {
      return article.title;
    },
  },
});
