import Vue from 'vue';

import { isMobileOnly } from 'mobile-device-detect';

export default Vue.extend({

  data: () => ({
    mixin__beforePrint__banners: [] as Array<any>,
  }),

  mounted () {
    if (isMobileOnly) {
      window.addEventListener('beforeprint', this.$__mixin__beforePrint__onBeforePrint);
      window.addEventListener('afterprint', this.$__mixin__beforePrint__onAfterPrint);
    }
  },

  beforeDestroy () {
    this.$__mixin__smartBanner__removeListener();
  },

  methods: {
    $__mixin__beforePrint__onBeforePrint (): void {
      const adfoxBannersSelector = 'body > div > a[href*="adfox"]';

      this.mixin__beforePrint__banners = Array
        .from(
          document.querySelectorAll(adfoxBannersSelector),
        )
        .map((banner: any) => {
          return {
            parentElement: banner.parentElement as HTMLElement,
            display: banner.parentElement.style.display,
          };
        });

      this.mixin__beforePrint__banners.forEach((banner: any) => {
        banner.parentElement.style.display = 'none';
      });
    },

    $__mixin__beforePrint__onAfterPrint (): void {
      this.mixin__beforePrint__banners.forEach((banner: any) => {
        banner.parentElement.style.display = banner.display;
      });
    },

    $__mixin__smartBanner__removeListener (): void {
      window.removeEventListener('beforeprint', this.$__mixin__beforePrint__onBeforePrint);
      window.removeEventListener('afterprint', this.$__mixin__beforePrint__onAfterPrint);
    },
  },
});
