import events from '../constants/events';
import { TNotification } from '~/store/pusher';

export function getNotificationContent (notification: TNotification, getObject = false) {
  let displayName, postTitle, text;

  switch (notification.event) {
    case 'comment_reply':
      displayName = notification.payload.extends.comment.user.display_name;
      postTitle = notification.payload.extends.postTitle;

      if (getObject) {
        return {
          name: displayName,
          content: `${events.comment_reply} <b>${postTitle}</b>`,
        };
      }

      return `<b>${displayName}</b> ${events.comment_reply} <b>${postTitle}</b>`;

    case 'comment_rated':
      displayName = notification.payload.extends.like.user.display_name;
      postTitle = notification.payload.extends.postTitle;

      text = notification.payload.content === 'like'
        ? events.comment_rated_like
        : events.comment_rated_dislike;

      if (getObject) {
        return {
          name: displayName,
          content: `${text} <b>${postTitle}</b>`,
        };
      }

      return `<b>${displayName}</b> ${text} <b>${postTitle}</b>`;

    case 'comment_publish':
      displayName = notification.payload.extends.commentUserName;
      postTitle = notification.payload.extends.postTitle;

      if (getObject) {
        return {
          name: displayName,
          content: `оставил(а) комментарий к статье <b>${postTitle}</b>`,
        };
      }

      return `<b>${displayName}</b> оставил(а) комментарий к статье <b>${postTitle}</b>`;
  }
  return '';
}

export function getPhotoStyle (notification: TNotification, onlyPhoto = false) {
  let photoUrl;
  switch (notification.event) {
    case 'comment_reply':
    case 'comment_publish':
      photoUrl = getUserAvatarUrlFromUser(notification.payload.extends.comment.user);
      return onlyPhoto ? photoUrl : {
        'background-image': `url(${photoUrl})`,
      };

    case 'comment_rated':
      photoUrl = getUserAvatarUrlFromUser(notification.payload.extends.like.user);
      return onlyPhoto ? photoUrl : {
        'background-image': `url(${photoUrl})`,
      };
  }
  return {};
}

export function getUserAvatarUrlFromUser (user: IUser) {
  let photoUrl = null;
  try {
    photoUrl = user.avatars[0].url;
  } catch (e) {
    photoUrl = getDefaultAvatar();
  }
  return photoUrl;
}

export function getDefaultAvatar () {
  return require('../images/default_avatar.jpg');
}
