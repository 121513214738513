const eventsMap = {
  comment_reply: 'ответил(а) вам под статьёй',
  email_verify: 'отправлено сообщение на почту для подтверждения email',
  email_confirm: 'подтвердил новый адрес почты',
  comment_rated_like: 'поставил(а) +1 комментарию к статье',
  comment_rated_dislike: 'поставил(а) -1 комментарию к статье',
};

export default eventsMap;
export const eventsArray = Object.keys(eventsMap);
