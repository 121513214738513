import type { Context } from '@nuxt/types';
import { SET_PAGE_TYPE } from '~/store/health';

export default async (ctx: Context): Promise<any> => {
  const { type } = await ctx.$healthApi.fetchTypeBySlug(ctx.route.path);
  if (!type) { return; }

  ctx.store.commit(SET_PAGE_TYPE, type);
  return type;
};
