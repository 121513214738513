type ArgType = (Promise<any> | undefined | null);
export type Resolved = { value: any; status: string };
export type Rejected = { reason: any; status: string };
type ReturnType = Promise<(Resolved | Rejected)[]>;

/**
 * Реализует Promise.allSettled
 * ранее использовался 3rd-party module https://www.npmjs.com/package/promise.allsettled, но у него баги
 * подробнее: https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Promise/allSettled
 * @param promises
 */
export function allSettled (promises: ArgType[]): ReturnType {
  const wrappedPromises = promises.map(p => Promise.resolve(p)
    .then(
      val => ({ status: 'fulfilled', value: val }),
      err => ({ status: 'rejected', reason: err }),
    ),
  );
  return Promise.all(wrappedPromises);
}
